import React from "react"
import * as Styled from "./styles"

const BoxInfoLink = ({
  icon,
  text,
  textColor = "#018383",
  buttonText,
  link,
  subscribed = false,
  buttonDisabled = false,
}) => {
  return (
    <Styled.Box>
      <Styled.Wrapper>
        {icon}
        <Styled.Info color={textColor}>{text}</Styled.Info>
      </Styled.Wrapper>
      <Styled.Button
        to={link}
        subscribed={subscribed}
        disabled={buttonDisabled}
      >
        {buttonText}
      </Styled.Button>
    </Styled.Box>
  )
}

export default BoxInfoLink
