import React from "react"

export default function PrivacyIcon({
  fill = "#018762",
  width = "22",
  height = "28",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0007 9.33333H17.6673V6.66667C17.6673 2.98667 14.6806 0 11.0007 0C7.32065 0 4.33398 2.98667 4.33398 6.66667V9.33333H3.00065C1.53398 9.33333 0.333984 10.5333 0.333984 12V25.3333C0.333984 26.8 1.53398 28 3.00065 28H19.0007C20.4673 28 21.6673 26.8 21.6673 25.3333V12C21.6673 10.5333 20.4673 9.33333 19.0007 9.33333ZM7.00065 6.66667C7.00065 4.45333 8.78732 2.66667 11.0007 2.66667C13.214 2.66667 15.0007 4.45333 15.0007 6.66667V9.33333H7.00065V6.66667ZM19.0007 25.3333H3.00065V12H19.0007V25.3333ZM11.0007 21.3333C12.4673 21.3333 13.6673 20.1333 13.6673 18.6667C13.6673 17.2 12.4673 16 11.0007 16C9.53398 16 8.33398 17.2 8.33398 18.6667C8.33398 20.1333 9.53398 21.3333 11.0007 21.3333Z"
        fill={fill}
      />
    </svg>
  )
}
