import styled, { css } from "styled-components"
import { Link } from "gatsby"

export const Box = styled.div`
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px 1px #ccc;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  grid-gap: 16px;
  align-items: center;
`

export const Info = styled.p`
  font-size: 14px;
  color: ${({ color }) => color};
`

const disabledStyle = css`
  color: #ffffff;
  background-color: #c7c7c7;
  border: 2px solid #c7c7c7;
`

const enabledStyle = css`
  color: ${({ subscribed }) => (subscribed ? "#018762" : "#fff")};
  background-color: ${({ subscribed }) => (subscribed ? "#fff" : "#018762")};
  border: 2px solid #018762;
`

export const Button = styled(Link)`
  ${({ disabled }) => (disabled ? disabledStyle : enabledStyle)}
  display: block;
  text-align: center;
  padding: 8px 0;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
  margin-top: 24px;
`
